
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


#customer-display-table {
  font-size: 1.25em;
}

.customer-display-table-header {
  background-color: #707275;
  color: white;
  padding-top: 3px;
  padding-bottom: 3px;
}

.customer-display-table-label {
  font-weight: bold;
  border-bottom: 1px solid #c8cbd1;
  padding-top: 3px;
  padding-bottom: 3px;
}

.customer-display-table-value {
  border-bottom: 1px solid #c8cbd1;
  padding-top: 3px;
  padding-bottom: 3px;
}
